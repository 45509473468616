import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import './scss/style.scss';

import { AppContext } from "./libs/contextLib"

import { Auth } from "aws-amplify"

import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {

  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    setIsAuthenticating(false);
  }

  return (
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <UnauthenticatedRoute exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <UnauthenticatedRoute exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <UnauthenticatedRoute exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <UnauthenticatedRoute exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <AuthenticatedRoute path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    </AppContext.Provider>
  );
}

export default App;
