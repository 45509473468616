const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "rocks.analytix",
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://pj0g3ldd24.execute-api.us-east-1.amazonaws.com/",
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_8icqXEG1u",
    APP_CLIENT_ID: "3in077u54h94oiubka2m0180kv",
    IDENTITY_POOL_ID: "us-east-1:6fed0d45-d153-4263-9c51-932ecbd69e64",
  },
};

export default config;
